define("discourse/plugins/discourse-shared-edits/discourse/templates/connectors/composer-fields-below/shared-edit-buttons", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if model.creatingSharedEdit}}
    <div class="leave-shared-edit">
      {{#if site.mobileView}}
        <a
          href
          {{action "endSharedEdit"}}
          tabindex="6"
          title={{i18n "shared_edits.done"}}
        >
          {{d-icon "times"}}
        </a>
      {{else}}
        <a href {{action "endSharedEdit"}} class="btn btn-primary" tabindex="6">
          {{i18n "shared_edits.done"}}
        </a>
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "AOgrp30x",
    "block": "[[[41,[30,0,[\"model\",\"creatingSharedEdit\"]],[[[1,\"  \"],[10,0],[14,0,\"leave-shared-edit\"],[12],[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"      \"],[11,3],[24,6,\"\"],[24,\"tabindex\",\"6\"],[16,\"title\",[28,[37,1],[\"shared_edits.done\"],null]],[4,[38,2],[[30,0],\"endSharedEdit\"],null],[12],[1,\"\\n        \"],[1,[28,[35,3],[\"times\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,3],[24,6,\"\"],[24,0,\"btn btn-primary\"],[24,\"tabindex\",\"6\"],[4,[38,2],[[30,0],\"endSharedEdit\"],null],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"shared_edits.done\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-shared-edits/discourse/templates/connectors/composer-fields-below/shared-edit-buttons.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `site` property path was used in the `discourse/plugins/discourse-shared-edits/discourse/templates/connectors/composer-fields-below/shared-edit-buttons.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.site}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"i18n\",\"action\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-shared-edits/discourse/templates/connectors/composer-fields-below/shared-edit-buttons.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});