define("discourse/plugins/discourse-shared-edits/discourse/connectors/composer-fields-below/shared-edit-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      endSharedEdit() {
        this.appEvents.trigger("composer:close");
      }
    }
  };
});